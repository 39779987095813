import request from '@/auth/jwt/request'

/**
 * @description 组织架构 -- 组织架构列表
 * @param {Object} param params {Object} 传值参数
 */
export const departListApi = params => { return request('depart/list', 'get', params) }

/**
 * @description 组织架构 -- 组织架构树
 * @param {Object} param params {Object} 传值参数
 */
export const departTreeApi = params => { return request('depart/tree', 'get', params) }

/**
 * @description 组织架构 -- 增加修改组织架构
 * id = 0 新增
 * @param {Object} param params {Object} 传值参数
 */
export const departSaveApi = data => { return request('depart/save', 'post', {}, data) }

/**
 * @description 组织架构 -- 组织架构状态管理
 * @param {Object} param params {Object} 传值参数
 */
export const departStatusApi = params => { return request('depart/status', 'get', params) }

/**
 * @description 组织架构 -- 组织架构详情
 * @param {Object} param params {Object} 传值参数
 */
export const departInfoApi = params => { return request('depart/info', 'get', params) }
/**
 * @description 组织架构 -- 删除组织架构
 * { id = x }
 */
export const departDeleteApi = data => { return request('depart/delete', 'delete', data) }